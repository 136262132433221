<template>
  <div>
    <el-cascader
      class="cascader"
      ref="area"
      v-model="area_ids"
      :options="area_data"
      :props="prop"
      placeholder="请选择省/市/区"
      v-loading="loading"
      :disabled="disabled"
      @change="handleChange"
    />
  </div>
</template>
<script>
import { areaGetList } from "@/api/practiceplanfreelyapplyinfo"
export default {
  props: {
    areaIds: {
      type: Array,
      default: () => new Array(),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    const that = this
    return {
      area_ids: [],
      area_data: [], //城市数据
      loading: false,
      prop: {
        lazy: true,
        lazyLoad: (node, resolve) => {
          const { level } = node
          const nodes = []  // 动态节点
          that.loading = true
          setTimeout(async () => {
            let type = level == 0 ? 0 : node.value  // 0 代表第一次请求
            let list = await that.getSta(type)
            //console.log(list);
            list.map((item) => {
              let area = {
                value: item.id,
                label: item.name,
                area_code: item.area_code,
                leaf: node.level >= 2,
              }
              nodes.push(area)
            })
            resolve(nodes) // 通过调用resolve将子节点数据返回，通知组件数据加载完成
            that.$nextTick(() => {
              that.loading = false // 加载完成
            })
          }, 500)
        }
      },
    }
  },
  watch: {
    areaIds: {
      handler (val) {
        if (!val.length) return false
        this.area_ids = val
      },
      immediate: true
    }
  },
  methods: {
    handleChange (val) {
      let data
      this.$nextTick(() => {
        data = this.$refs["area"].getCheckedNodes(true)
        this.$emit('areaCodes', data[0].data)
      })
      this.$emit('updateArea', val)
    },
    async getSta (code) {
      let list = []
      let res = await areaGetList({ pid: code })
      if (res && res.code === 0) {
        list = res.data
      }
      return list
    },
  },
};
</script>
<style lang="scss">
.cascader {
  width: 100%;
}
</style>