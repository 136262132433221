import { render, staticRenderFns } from "./editorregistrationname.vue?vue&type=template&id=0f4e956f&scoped=true"
import script from "./editorregistrationname.vue?vue&type=script&lang=js"
export * from "./editorregistrationname.vue?vue&type=script&lang=js"
import style0 from "./editorregistrationname.vue?vue&type=style&index=0&id=0f4e956f&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0f4e956f",
  null
  
)

export default component.exports