<template>
  <!-- 重新报名 -->
  <div class="mainpage">
    <div class="head">
      <div class="head-title">实习详情({{ iteminfo.status | typeformat }})</div>
      <div class="retutnbt" @click="toback">返回</div>
    </div>
    <div class="line"></div>
    <div class="maincontain">
      <div class="h-contain">
        <div class="h-title">
          <div class="icon"></div>
          <div class="text">实习信息</div>
        </div>
        <div class="h-title2">{{ user_info.plan_name }}</div>
        <div class="h-title3">
          <p>{{ user_info.name }}&nbsp;&nbsp;&nbsp;&nbsp; {{ user_info.student_sn }}</p>
          <p>{{ user_info.college_name }} / {{ user_info.major_name }} / {{ user_info.class_name }}</p>
        </div>
      </div>
      <div class="h2-contain">
        <div class="h2-title">
          <div class="icon"></div>
          <div class="text">实习信息</div>
        </div>
        <div class="bodycontain">
          <el-form class="elform" ref="formone" :model="formone" label-position="top">
            <el-form-item label="实习单位" class="elformitem">
              <el-input v-model="formone.company" placeholder="请输入实习单位" disabled></el-input>
            </el-form-item>
            <el-form-item label="实习岗位" class="elformitem">
              <el-input disabled v-model="formone.job" placeholder="请输入实习岗位"></el-input>
            </el-form-item>
            <el-form-item label="实习时间" class="elformitem">
              <el-date-picker
                disabled
                v-model="formone.timearr"
                type="datetimerange"
                start-placeholder="实习开始时间"
                end-placeholder="实习结束时间"
              ></el-date-picker>
            </el-form-item>

            <el-form-item label="实习方式" class="elformitem">
              <el-select disabled class="elformitem" v-model="formone.mode" placeholder="请选择">
                <el-option
                  v-for="(item, index) in optiontwo"
                  :key="index"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="实习地点" class="elformitem3">
              <el-input disabled v-model="formone.address" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item label="签到地点" class="elformitem3" prop="sign_in_address">
              <div class="map">
                <el-input disabled v-model="formone.sign_in_address" placeholder="请输入"></el-input>
              </div>
            </el-form-item>
            <el-form-item label="实习待遇（元）" class="elformitem">
              <el-input disabled v-model="formone.salary" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item label="实习单位接收函" class="elformitem">
              <div class="file-select-contain">
                <div class="file-title">{{ formone.receiving_letter ? '单位接收函已上传' : '未选择文件' }}</div>
                <el-button
                  type="text"
                  class="file-selecttip"
                  :disabled="!formone.receiving_letter"
                  @click="download(formone.receiving_letter)"
                >下载</el-button>
              </div>
            </el-form-item>
            <el-form-item label="安全承诺书" class="elformitem">
              <div class="file-select-contain">
                <div class="file-title">{{ formone.safety_letter ? '安全承诺书已上传' : '未选择文件' }}</div>
                <el-button
                  type="text"
                  class="file-selecttip"
                  :disabled="!formone.safety_letter"
                  @click="download(formone.safety_letter)"
                >下载</el-button>
              </div>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <div class="h2-contain">
        <div class="h2-title">
          <div class="icon"></div>
          <div class="text">实习单位信息</div>
        </div>
        <div class="bodycontain">
          <el-form class="elform" ref="formtwo" :model="formtwo" label-position="top">
            <el-form-item label="实习单位统一社会信用代码" prop="credit_code" class="elformitem">
              <el-input
                disabled
                v-model="formtwo.credit_code"
                placeholder="请输入"
                onkeyup="value=value.replace(/[^\w\.\/]/ig,'')"
              ></el-input>
            </el-form-item>
            <el-form-item label="实习地区" prop="areaIds" class="elformitem">
              <vArea
                :area-ids="areaIds"
                :disabled="true"
                @updateArea="changeArea"
                @areaCodes="areaCodes"
              ></vArea>
            </el-form-item>
            <el-form-item label="地区代码" prop="address_code" class="elformitem">
              <el-input disabled v-model="formtwo.address_code" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item label="单位联系人" class="elformitem">
              <el-input disabled v-model="formtwo.company_user" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item label="单位电话" class="elformitem">
              <el-input disabled v-model="formtwo.company_tel" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item label="单位地址" class="elformitem">
              <el-input disabled v-model="formtwo.company_address" placeholder="请输入"></el-input>
            </el-form-item>
          </el-form>
        </div>
      </div>

      <div class="h2-contain">
        <div class="h2-title">
          <div class="icon"></div>
          <div class="text">家庭信息信息</div>
        </div>
        <div class="bodycontain">
          <el-form class="elform" ref="formthree" :model="formthree" label-position="top">
            <el-form-item label="家庭住址" class="elformitem">
              <el-input disabled v-model="formthree.family_address" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item label="联系电话" class="elformitem">
              <el-input disabled v-model="formthree.family_tel" placeholder="请输入"></el-input>
            </el-form-item>
          </el-form>
        </div>
      </div>

      <div class="h2-contain">
        <div class="h2-title">
          <div class="icon"></div>
          <div class="text">申请理由</div>
        </div>
        <div class="bodycontain">
          <el-form class="elform" ref="formfour" :model="formfour" label-position="top">
            <el-form-item label="申请理由" class="elformitem">
              <el-input
                disabled
                class="elinput"
                type="textarea"
                :autosize="{ minRows: 6, maxRows: 8 }"
                placeholder="请输入内容"
                v-model="formfour.apply_reason"
              ></el-input>
            </el-form-item>
          </el-form>
        </div>
      </div>

      <div class="h2-contain" v-if="iteminfo.status == 1">
        <div class="h2-title">
          <div class="icon"></div>
          <div class="text">驳回理由</div>
        </div>
        <div class="bodycontain">
          <el-form class="elform" ref="formfour" :model="formfour" label-position="top">
            <el-form-item label="驳回理由" class="elformitem">
              <el-input
                disabled
                class="elinput"
                type="textarea"
                :autosize="{ minRows: 6, maxRows: 8 }"
                placeholder="请输入内容"
                v-model="formfour.reply_reason"
              ></el-input>
            </el-form-item>
          </el-form>
        </div>
      </div>

      <div class="h6-contain">
        <!-- <el-button class="bt1" @click="toback">返回</el-button> -->
        <el-button class="bt" @click="signupname">编辑</el-button>
      </div>
    </div>
  </div>
</template>

<script>
const typemap = ["待审核", "审核驳回", "审核通过"]
import { getinternshipregistrationinfo, areaGetList } from "@/api/practiceplanfreelyapplyinfo"
import vArea from '@/components/Area/index.vue'
export default {
  name: "mainpage",
  components: {
    vArea
  },
  filters: {
    typeformat (val) {
      return typemap[val]
    },
  },
  data () {
    return {
      user_info: '',
      formone: {
        company: "",
        job: "",
        timearr: [],
        start_date: "",
        end_date: "",
        mode: "",
        address: "",
        sign_in_address: "",
        lng: "",
        lat: "",
        salary: "",
        receiving_letter: "",
        safety_letter: "",
      },
      formtwo: {
        company_user: "",
        company_tel: "",
        company_address: "",
        credit_code: '', // 社会信用代码
        address_code: '', // 行政代码
        province_area_id: '', // 省级id
        city_area_id: '', // 市级id
        region_area_id: '' // 区级id
      },
      formthree: {
        family_address: "",
        family_tel: "",
      },
      formfour: {
        apply_reason: "",
        reply_reason: "",
      },

      optionone: [],
      areaIds: [],
      optiontwo: [
        {
          value: 0,
          label: "其他",
        },
        {
          value: 1,
          label: "参观",
        },
        {
          value: 2,
          label: "顶岗",
        },
        {
          value: 3,
          label: "跟岗",
        },
      ],
      iteminfo: {},
    }
  },
  computed: {
    freely_apply_info_id () {
      return this.$route.query.freely_apply_info_id
    }
  },
  created () {
    this.getinternshipregistrationinfo({
      id: this.freely_apply_info_id,
    })
  },
  methods: {
    async initArea (data) {
      let _this = this
      await areaGetList({ pid: data }).then(res => {
        if (res) {
          res.data.map((item, index) => {
            _this.options.push({
              value: item.id,
              label: item.name,
              area_code: item.area_code,
              children: []
            })
          })

        }
      })
    },
    changeArea (data) {
      this.formtwo.province_area_id = data[0]
      this.formtwo.city_area_id = data[1]
      this.formtwo.region_area_id = data[2]
      // this.areaCodes()
    },
    // 行政代码
    areaCodes (data) {
      if (data.value === this.formtwo.region_area_id) {
        this.formtwo.address_code = data.area_code
      }
    },
    toback () {
      this.$router.push({
        path: "/home/personalcenter/mainpage",
        query: {
          id: 6,
          componentId: "Myinternship",
        },
      })
    },
    //报名
    signupname () {
      this.$router.push({
        path: "/home/personalcenter/registrationname",
        query: {
          type: "编辑",
          freely_apply_info_id: this.freely_apply_info_id,
          plan_id: this.iteminfo.plan_id,
          student_id: this.iteminfo.student_id,
        },
      })
    },
    download (url) {
      window.open(url)
    },
    getinternshipregistrationinfo (params) {
      getinternshipregistrationinfo(params)
        .then((response) => {
          if (response.code === 0) {
            this.user_info = response.data.user_info
            this.formone.company = response.data.info.company
            this.formone.job = response.data.info.job
            const start_date = response.data.info.start_date * 1000
            const end_date = response.data.info.end_date * 1000
            this.formone.timearr = [start_date, end_date]
            this.formone.mode = response.data.info.mode
            this.formone.address = response.data.info.address
            this.formone.sign_in_address = response.data.info.sign_in_address
            this.formone.salary = response.data.info.salary
            this.formone.receiving_letter = response.data.info.receiving_letter
            this.formone.safety_letter = response.data.info.safety_letter
            this.formtwo.company_user = response.data.info.company_user
            this.formtwo.company_tel = response.data.info.company_tel
            this.formtwo.company_address = response.data.info.company_address
            this.formthree.family_address = response.data.info.family_address
            this.formthree.family_tel = response.data.info.family_tel
            this.formfour.apply_reason = response.data.info.apply_reason
            this.formfour.reply_reason = response.data.info.reply_reason
            this.formtwo.credit_code = response.data.info.credit_code
            this.formtwo.address_code = response.data.info.address_code // 行政代码
            this.formtwo.province_area_id = response.data.info.province_area_id // 省级id
            this.formtwo.city_area_id = response.data.info.city_area_id // 市级id
            this.formtwo.region_area_id = response.data.info.region_area_id // 区级id
            this.iteminfo = {
              status: response.data.info.status,
              plan_id: response.data.info.practice_plan_id,
              student_id: response.data.info.student_id,
            }
            this.areaIds.push(response.data.info.province_area_id, response.data.info.city_area_id, response.data.info.region_area_id)
          }
        })
        .catch((error) => {
          //console.log(error);
        })
    },
  },
};
</script>
<style scoped lang="scss">
.mainpage {
  margin-bottom: 40px;
  min-height: calc(100vh - 210px);
  background: #fff;

  .head {
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 10px;

    .head-title {
      font-size: 20px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #3d84ff;
      user-select: none;
      white-space: nowrap;
      padding-left: 10px;
    }

    .retutnbt {
      margin-right: 40px;
      width: 64px;
      height: 38px;
      line-height: 38px;
      text-align: center;
      background: #3d84ff;
      border-radius: 4px;
      color: white;
      user-select: none;
      cursor: pointer;
      font-size: 14px;
    }
  }

  .line {
    margin-top: 10px;
    margin-bottom: 4px;
    border-bottom: 2px solid #cccccc;
  }

  .maincontain {
    background: #ffffff;
    border-radius: 4px;

    .h-contain {
      height: 160px;
      background: #fff;
      box-sizing: border-box;
      padding: 20px;

      .h-title {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .icon {
          height: 15px;
          border-right: 4px solid #666666;
        }

        .text {
          margin-left: 5px;
          font-size: 20px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #222222;
        }
      }

      .h-title2 {
        margin-top: 10px;
        font-size: 20px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #222222;
      }

      .h-title3 {
        margin-top: 10px;
        font-size: 15px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #222222;
      }
    }

    .h2-contain {
      margin-top: 20px;
      background: #fff;
      box-sizing: border-box;
      padding: 20px;

      .h2-title {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .icon {
          height: 15px;
          border-right: 4px solid #666666;
        }

        .text {
          margin-left: 5px;
          font-size: 20px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #222222;
        }
      }

      .bodycontain {
        margin-top: 20px;

        .elform {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          flex-wrap: wrap;
          margin-left: 40px;

          .elformitem {
            margin-right: 40px;
            width: 400px;

            .file-select-contain {
              display: flex;
              justify-content: flex-start;
              align-items: center;

              .file-title {
                color: #c0c4cc;
                width: 75%;
                height: 40px;
                overflow: hidden;
                background-color: #f5f7fa;
                border: 1px solid #e4e7ed;
                border-radius: 4px;
                padding-left: 10px;
              }

              .file-selecttip {
                margin-left: 10px;
                padding-left: 10px;
                text-align: left;
                font-size: 14px;
                font-weight: 400;
              }
            }
          }

          .elformitem2 {
            margin-right: 10px;
            width: 180px;
          }

          .elformitem3 {
            margin-right: 40px;
            width: 400px;

            .map {
              display: flex;
              justify-content: space-between;
              align-items: center;

              .elimage {
                margin-left: 10px;
                widows: 30px;
                height: 30px;
                cursor: pointer;
              }
            }
          }
        }
      }
    }

    .h6-contain {
      margin-top: 20px;
      height: 80px;
      background: #fff;
      box-sizing: border-box;
      padding: 20px;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .bt {
        background: #3d84ff;
        color: #ffffff;
      }

      .bt1 {
        background: #ffffff;
        color: #3d84ff;
      }
    }
  }
}
</style>
